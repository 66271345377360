import React, { Component, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useState } from 'react';

const langArray = ['ru', 'en', 'de'];

function SelectLang(props) {

  const handleLang = (item) => {
    props.changeLang(item)
  }
  const [isOpenMenu, setOpenMenu] = useState(false)

  let wrapperRef = useRef()


  const onClickSelectLang = (e) => {
    setOpenMenu(!isOpenMenu)
  }

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setOpenMenu(false)
    }
  }

  useEffect(() => { document.addEventListener('mousedown', handleClickOutside) }, [])




  return (
    <>
      <div
        className='select_lang'
        onClick={onClickSelectLang}
        ref={wrapperRef}
      >
        <div className='label'>
          <span>{props.lang}</span>
        </div>
        {isOpenMenu && (
          <div className='langs'>
            {langArray.map((item, index) => {
              return (
                <div key={index}
                  className={`item${props.lang === item ? ' act' : ''}`} onClick={() => handleLang(item)} >
                  {item}
                </div>
              )
            })}
          </div>
        )}
      </div>
      <div className='langs-side-menu'>
        {langArray.map((item, index) => {
          return (
            <div onClick={() => props.changeLang(item)} key={index}
              className={`item${props.lang === item ? ' active' : ''}`}
            >
              {item}
            </div>
          )
        })}
      </div>
    </>
  )

}


export default withRouter(SelectLang)
