import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Link as LinkToScroll } from 'react-scroll'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization'
import Hamburger from 'hamburger-react'

import { useState } from 'react';
// components
import SelectLang from './SelectLang'
//css
import './nav.css'
//img
import FmcImg from '../../../../img/Logotype.png'

let strings = new LocalizedStrings({
  en: {
    items: [
      { name: 'services', href: 'services' },
      { name: 'prices', href: 'prices' },
      { name: 'contacts', href: 'contacts' },
      { name: 'our work', href: '/works' },
    ],
  },
  ru: {
    items: [
      { name: 'услуги', href: 'services' },
      { name: 'стоимость', href: 'prices' },
      { name: 'связаться с нами', href: 'contacts' },
      { name: 'наши работы', href: '/works', },
    ],
  },
  de: {
    items: [
      { name: 'Unser Angebot ', href: 'services' },
      { name: 'Preise', href: 'prices' },
      { name: 'Kontaktieren Sie uns', href: 'contacts' },
      { name: 'unsere Werke', href: '/works' },
    ],
  }
})


function Nav(props) {
  const [visible, setVisible] = useState(false)

  const hadnleVisible = () => {
    setVisible(!visible)
  }

  const history = useHistory()


  const goToPage = (href) => {
    history.push(`/#${href}`)
  }



  strings.setLanguage(props.lang)
  return (
    <>
      <div className="navigator">
        <div className="container">
          <nav className={`navi${visible ? ' visible' : ''}`}>
            <div className='navi__logo-wrap'>
              <Link to={'/'} className='navi__logo'>
                <img src={FmcImg} alt='FMC-dev' width='122' height='44' />
              </Link>
            </div>


            <ul className='navi__navigation'>
              {props.isScroll ?
                strings.items.map((item, index) => {
                  return (
                    item.href === '/works' ?
                      < li key={index}  >
                        <NavLink to={item.href} className='navi__link'>
                          <span className='navi__item-text'>{item.name}</span>
                        </NavLink>
                      </li> : < li key={index}  >
                        <LinkToScroll onClick={hadnleVisible} to={item.href} smooth={true} duration={1500}
                          className='navi__link'>
                          <span className='navi__item-text'>{item.name}</span>
                        </LinkToScroll>
                      </li>
                  )
                }) : strings.items.map((item, index) => {
                  return (
                    item.href === '/works' ?
                      < li key={index}  >
                        <NavLink to={item.href} className='navi__link'>
                          <span className='navi__item-text'>{item.name}</span>
                        </NavLink>
                      </li> : < li key={index} >
                        <Link className='navi__link' onClick={() => { goToPage(item.href) }}>
                          <span className='navi__item-text'>{item.name}</span>
                        </Link>
                      </li>
                  )
                })
              }
            </ul>

            <SelectLang changeLang={props.changeLang} lang={props.lang} visible={visible} />
            <div className='hamburger'>
              <Hamburger toggled={visible} toggle={hadnleVisible} size={18} />
            </div>
          </nav>
        </div>
      </div>
    </>
  )

}

export default Nav
