import React, { Component } from 'react'
import LocalizedStrings from 'react-localization'
import FooterForm from './FooterForm'
/* IE 11 fetch */
import { fetch as fetchPolyfill } from 'whatwg-fetch'
// style
import './footer.css'

const strings = new LocalizedStrings({
  en: {
    section_title: 'Prices',
    section_description_first:
      'The cost of our services is dynamic, depends on the specific task. Payment is made through invoice.',
    section_description_second: 'You can also call the number:',
    phone: '+38 (093) 919-25-23',
    email_title: 'Or contact us via e-mail:',
    email: 'fred@fmc-dev.com',
    form_title: 'Contact us and together we will calculate the cost:',
    input_name: 'Your name',
    input_contact: 'Phone, e-mail or skype',
    input_comment: 'Comment',
    button_name: 'Submit',
    button_loading: 'Sending',
    form_validation: {
      name: 'Please enter your name',
      name_value: 'Only letters allowed!',
      name_length: 'Max 128 symbols',
      contacts: 'Please enter your Contacts',
      contacts_length: 'Max 128 symbols',
      comment_length: 'Max 1000 symbols',
      response_valid: 'Message has been sent',
      response_warning: 'Message in the waiting list',
      response_error: 'An error has occurred'
    },
    btn_offer: 'Offer Agreement'
  },
  ru: {
    section_title: 'Стоимость',
    section_description_first:
      'Стоимость наших услуг динамическая, зависит от конкретной задачи. Оплата проводится через инвойс.',
    section_description_second: 'Также, вы можете позвонить по номеру:',
    phone: '+38 (093) 919-25-23',
    email_title: 'Или написать на почту:',
    email: 'fred@fmc-dev.com',
    form_title: 'Свяжитесь с нами и мы вместе рассчитаем стоимость:',
    input_name: 'Ваше имя',
    input_contact: 'Телефон, e-mail или skype',
    input_comment: 'Комментарий',
    button_name: 'Отправить',
    button_loading: 'Отправка',
    form_validation: {
      name: 'Укажите, пожалуйста, Ваше имя',
      name_value: 'Допустимы только буквы!',
      name_length: 'Максимум 128 символов!',
      contacts: 'Укажите, пожалуйста, Ваши контактные данные',
      contacts_length: 'Максимум 128 символов!',
      comment_length: 'Максимум 1000 мимволов!',
      response_valid: 'Сообщение отправлено',
      response_warning: 'Сообщение в списке ожидания',
      response_error: 'Возникла ошибка'
    },
    btn_offer: 'Договор Оферты'
  },
  de: {
    section_title: 'Preise',
    section_description_first:
      'Die Kosten unserer Dienstleistungen sind dynamisch und hängen von der jeweiligen Aufgabe ab. Die Zahlung erfolgt per Rechnung.',
    section_description_second: 'Kontaktieren Sie uns unter der Tel. Nr.:',
    phone: '+49 176 23 58 92 18',
    email_title: 'Oder per E-Mail:',
    email: 'alexander.grinbuch@fmc-dev.com',
    form_title: 'Kontaktieren Sie uns und wir berechnen gemeinsam die Kosten:',
    input_name: 'Ihr Name',
    input_contact: 'Telefon, E-Mail oder Skype',
    input_comment: 'Nachricht/Kommentar',
    button_name: 'Senden',
    button_loading: 'Absendung',
    form_validation: {
      name: 'Bitte geben Sie Ihren Namen ein',
      name_value: 'Nur Buchstaben erlaubt!',
      name_length: 'Maximal 128 Symbole',
      contacts: 'Bitte geben Sie Kontaktdaten ein',
      contacts_length: 'Maximal 128 Symbole',
      comment_length: 'Maximal 1000 Symbole',
      response_valid: 'Nachricht wurde gesendet',
      response_warning: 'Nachricht in der Warteliste',
      response_error: 'Ein Fehler ist aufgetreten'
    },
    btn_offer: 'Vertragsangebot'
  }
})

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      date: new Date().getFullYear() + ' ',
    }
  }



  render() {
    strings.setLanguage(this.props.lang)

    const {
      date,
    } = this.state


    const emailHref = `mailto:${strings.email}`;
    const telHref = `tel:${strings.phone}`.replace(/-|\s|\(|\)/g, "");

    return (
      <footer className='footer' id='contacts' >
        <div className='background-div'  > </div>
        <div id='prices' className='container'>
          <div className='row no-gutters'>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='header-title'>
                <h2>{strings.section_title}</h2>
              </div>
              <div className='description'>
                <div className='first-p'>
                  {strings.section_description_first}
                </div>
                <div className='second-p'>
                  {strings.section_description_second}
                </div>
                <a className='phone' href={telHref}>
                  {strings.phone}
                </a>
                <div className='third-p'>{strings.email_title}</div>
                <a className='phone' href={emailHref}>
                  {strings.email}
                </a>
                <div className="offer">
                  <a className='phone' href={`/offer`}>
                    {strings.btn_offer}
                  </a>
                </div>
      
              </div>
     
              <div className='afterfooter'>
                <span>{date}</span>
                <span>Fmc-dev</span>
              </div>
            </div>
            <div
              id='contacts'
              className='col-12 offset-0 offset-lg-2 col-md-6'
            >
              <FooterForm lang={this.props.lang} place='Main' />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
