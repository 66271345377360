//configuration fore IE9-11
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/fn/object/assign';
import 'raf/polyfill';
import 'es6-promise';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';

//import bootstrap 4
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/App/reset.css';
//import semantic-ui css
import 'semantic-ui-css/semantic.min.css';
//slick-slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));
