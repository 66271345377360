import React, { Component } from 'react';
import './web-animation.css';

function WebAnimation() {

    return (
        <div className="web-animation">
            <div className="main_block">
                <div className="order_wheel">
                    <div className="circle_lvl_1"></div>
                    <div className="circle_lvl_2"></div>
                    <div className="circle_lvl_3"></div>
                    <div className="circle_lvl_4"></div>
                </div>
                <div className="order_page">
                    <div className="title"></div>
                    <div className="body_text">
                        <div className="parag raf_1 blue"></div>
                        <div className="parag raf_2 blue"></div>
                        <div className="parag raf_3 white"></div>
                        <div className="parag raf_4 white"></div>
                        <div className="parag raf_5 white"></div>
                        <div className="parag raf_6 blue"></div>
                        <div className="parag raf_7 blue"></div>
                        <div className="parag raf_8 white"></div>
                        <div className="parag raf_9 white"></div>
                        <div className="parag raf_10 white"></div>
                        <div className="parag raf_11 blue"></div>
                        <div className="parag raf_12 blue"></div>
                        <div className="parag raf_13 white"></div>
                        <div className="parag raf_14 white"></div>
                        <div className="parag raf_15 white"></div>
                        <div className="parag raf_16 blue"></div>
                        <div className="parag raf_17 blue"></div>
                    </div>
                </div>
                <div className="order_keyboard">
                    <div className="active-buttons">
                        <div className="button_1"></div>
                        <div className="button_2"></div>
                        <div className="button_3"></div>
                        <div className="button_4"></div>
                        <div className="button_5"></div>
                        <div className="button_6"></div>
                        <div className="button_7"></div>
                        <div className="button_8"></div>
                        <div className="button_9"></div>
                        <div className="button_10"></div>
                        <div className="button_11"></div>
                        <div className="button_12"></div>
                        <div className="button_13"></div>
                        <div className="button_14"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default WebAnimation;