import React from 'react'
import BottonMore from '../../BottonMore/BottonMore'
import WorkCart from '../../WorkCart/WorkCart'
import './AnotherProjects.scss'


const AnotherProjects = () => {
  return (
    <div className="project-similar g-margin-xl">
      <div className="container">
        <h3 className="project-similar__title">Похожие проекты</h3>
      </div>
      {/*    <div className="project-similar__carts">
        <WorkCart img={image1} text={'Neitronic'} class='margin' />
        <WorkCart img={image2} text={'Neitronic'} class='margin' />
        <WorkCart img={image3} text={'Neitronic'} class='margin' />
      </div> */}
      <div className="container">
        <div className='project-similar__botton '>
          <BottonMore >
            Load more
          </BottonMore>
        </div>
      </div>
    </div>

  )
}

export default AnotherProjects
