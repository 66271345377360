import React, { Component } from 'react';
import './seo-animation.css';

function SeoAnimation() {

    return (
        <div className="seo-animation">
            <div className="leptop-botom"></div>
            <div className="leptop-line"></div>
            <div className="leptop-scrin"></div>
            <div className="leptop-scrin1"></div>
            <div className="dot1 slide-left-dot1"></div>
            <div className="dot2 slide-left-top"></div>
            <div className="dot3 slide-left-top"></div>
            <div className="dot4 slide-left-top"></div>
            <div className="dot5 slide-left-top"></div>
            <div className="infiniti-antena">
                <div className="dot6 slide-top-center"></div>
                <div className="dot7 slide-top-center"></div>
                <div className="dot8 slide-top-center"></div>
                <div className="blueblcok slide-top-center"></div>
            </div>
            <div className="dot9 slide-right-top-dot9"></div>
            <div className="dot10 slide-right-top-dot10"></div>
            <div className="dot11 slide-right-top-dot11"></div>
            <div className="dot12 slide-right-top-dot12"></div>
            <div className="slide-right-bottom-wrapper slide-right-bottom-black">
                <div className="dot13"></div>
                <div className="dot14"></div>
                <div className="dot15"></div>
                <div className="dot16"></div>
                <div className="blackblcok2 blackblcok2-animation"></div>
            </div>
            <div className="blackblcok1 slide-left-bottom"></div>
            <div className="redcircle1 slide-left-top"></div>
            <div className="redcircle2 slide-right-top"></div>

            <div className="bluecircle"></div>
            <div className="redblock1"></div>
            <div className="blackblcok3"></div>
            <div className="redblock2"></div>
            <div className="left-text1"></div>
            <div className="left-text2"></div>
            <div className="left-text3"></div>
            <div className="left-text4"></div>
            <div className="left-text5"></div>
            <div className="right-text1"></div>
            <div className="right-text2"></div>
            <div className="right-text3"></div>
            <div className="right-text4"></div>
            <div className="right-text5"></div>
        </div>
    )
}


export default SeoAnimation;