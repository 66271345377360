import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import FooterForm from '../Main/Home/Footer/FooterForm';
import './footercomp.css'


const strings = new LocalizedStrings({
    en: {
        contacts: 'CONTACTS',
        section_description_second: 'You can also call the number:',
        phone: '+38 (093) 919-25-23',
        email_title: 'Or contact us via e-mail:',
        email: 'fred@fmc-dev.com',
        btn_offer: 'Offer Agreement'
    },
    ru: {
        contacts: 'КОНТАКТЫ',
        section_description_second: 'Также, вы можете позвонить по номеру:',
        phone: '+38 (093) 919-25-23',
        email_title: 'Или написать на почту:',
        email: 'fred@fmc-dev.com',
        btn_offer: 'Договор Оферты'
    },
    de: {
        contacts: 'KONTAKT',
        section_description_second: 'Kontaktieren Sie uns unter der Tel. Nr.:',
        phone: '+49 176 23 58 92 18',
        email_title: 'Oder per E-Mail:',
        email: 'alexander.grinbuch@fmc-dev.com',
        btn_offer: 'Vertragsangebot'
    },
});

function FooterComp(props) {


    strings.setLanguage(props.lang)
    return (

        <footer className='footerblock'>
            <div className='container '>
                <div className='row no-gutters'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className="footerblock__desc">
                            <div className="footerblock__title">
                                <h2 >{strings.contacts}</h2>
                            </div>
                            <div className="footerblock__num">
                                <p> {strings.section_description_second}</p>
                                <a href={`tel:${strings.phone}`.replace(/-|\s|\(|\)/g, "")}>
                                    <p>{strings.phone}</p>
                                </a>
                            </div>
                            <div className="footerblock__email">
                                <p>{strings.email_title}</p>
                                <a href={`mailto:${strings.email}`}>
                                    <p> {strings.email}</p>
                                </a>
                                <br />
                                <a className='phone' href={`/offer`}>
                                    {strings.btn_offer}
                                </a>
                            </div>
                        </div>
                        <div className='footerblock__after'>
                            <span>{new Date().getFullYear() + ' '}</span>
                            <span>Fmc-dev</span>
                        </div>
                    </div>
                    <div className='col-12 offset-0 offset-lg-2 col-md-6'>
                        <FooterForm lang={props.lang} place='Our work' />
                    </div>

                </div>
            </div>
        </footer>


    )

}




export default FooterComp