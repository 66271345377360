import React, { useEffect } from 'react';
import Header from './Header/Header';
import Nav from './Header/Nav'
import ServicesBlock from './Services/ServicesBlock';
import ServicesImg from './Services/ServicesImg';
import Footer from './Footer/Footer';
import { stubTrue } from 'lodash';

function Home(props) {

    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
        console.log('isIE ? -' + isIE + ' v1.1');
    }


    useEffect(() => {
        const { hash } = window.location;
        if (hash !== '') {
            // Push onto callback queue so it runs after the DOM is updated,
            // this is required when navigating from a different page so that
            // the element is rendered on the page before trying to getElementById.
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }, [])

    return (
        <main className="Home">
            <Nav changeLang={props.changeLang} lang={props.lang} isScroll={true} />
            <Header lang={props.lang} />
            <ServicesBlock lang={props.lang} />
            <ServicesImg lang={props.lang} />
            <Footer lang={props.lang} />
        </main>
    )

}

export default Home;



