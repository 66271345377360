import React from 'react'

const OfferEN = () => {
  return (
    <div>
      <div className='header-title'>
        <h2>PUBLIC OFFER AGREEMENT FOR PAID SERVICES</h2>
      </div>
<p>1.1. This document is a Public Offer of the FMC-dev company (private entrepreneur Melnychuk Friedrich Oleksandrovych, entry in the Unified State Register No. 20260000000041685 dated February 14, 2018) and (or) its agents / partners (if they are indicated in the invoices for services), hereinafter referred to as &quot;Contractor&quot; and contains all the essential conditions for the provision of information and consulting services.</p>
<p>1.2. The Public Offer is an official document and is published on the Contractor&apos;s website at the following address:</p>
<p><a href="https://fmc-dev.com/offer">https://fmc-dev.com/offer</a></p>
<p>1.3. In the case of payment for services, a legal or natural person thereby confirms the acceptance of the conditions of the Offer set out below and becomes the Customer, and the Contractor and the Customer jointly become the Parties to the Offer Agreement.</p>
<p>1.4. The Offer Agreement does not require sealing and signing by the Customer, while maintaining full legal force.</p>
<p>1.5. In connection with the above, the Customer is obliged to carefully read the text of this Offer and, in case of disagreement with the terms and conditions, refuse to conclude the Offer agreement and use the Contractor&apos;s services.</p>
<p>2. SUBJECT OF THE AGREEMENT-OFFER</p>
<p>2.1. In accordance with the terms of this Agreement, the Contractor undertakes to provide the Services specified in the issued invoice, and the Customer undertakes to pay for these Services in accordance with the invoice issued..</p>
<p>3. ACCEPTANCE OF THE OFFER AND CONCLUSION OF THE AGREEMENT-OFFER</p>
<p>3.1. The Customer makes an Acceptance of the Offer by prepayment for the Contractor&apos;s Services, in respect of which the Offer Agreement is concluded. Acceptance by the Customer of this Agreement means that he fully agrees with all the provisions of this Agreement.</p>
<p>3.2. By accepting the Offer in the manner specified in clause 3.1 of the Agreement, the Customer guarantees that he is familiar with, agrees to, fully and unconditionally accepts all the terms of the Agreement in the form in which they are set forth.</p>
<p>4. RIGHTS AND OBLIGATIONS OF THE PARTIES</p>
<p>4.1. The Contractor undertakes:</p>
<p>4.1.1. Organize and ensure the proper provision of the Services.</p>
<p>4.1.2. Use all personal data and other confidential information about the Customer only for the provision of Services, do not transfer or show to third parties the documentation and information about the Customer held by him.</p>
<p>4.1.3. Provide oral and written consultations on additional issues of the Customer. The volume and terms of consultation, as well as the form of consultation, are determined in each specific case by the Contractor independently.</p>
<p>4.1.4. The client is obliged to provide all documents and perform all relevant actions that lie in the plane of KYC, DD processes on him and his affiliates, if this mandatory norm is established by the country where the client purchases services.</p>
<p>4.2. The Contractor has the right:</p>
<p>4.2.1. Unilaterally determine the cost of all Services provided and change the terms of this Agreement, except for already concluded agreements.</p>
<p>4.2.2. Independently determine the forms and methods for the provision of Services based on the requirements of the law, technical capabilities, as well as the specific terms of the Agreement, taking into account the wishes of the Customer.</p>
<p>4.2.3. Use the services of any natural and legal persons, in order to fulfill obligations under the Agreement in a timely and high-quality manner. Independently determine the composition of specialists providing the Services, and at its own discretion distribute work among them.</p>
<p>4.2.4. Demand payment for Services rendered or being rendered.</p>
<p>4.2.5. Refuse the Customer to provide the service in case of non-payment (incomplete payment) for the services within the established time limits, in case of untimely submission of the application for the provision of the service.</p>
<p>4.2.6. Receive from the Customer any information necessary to fulfill its obligations under the Agreement. In case of non-provision or incomplete or incorrect provision of information by the Customer, the Contractor has the right to suspend the performance of its obligations under the Agreement until the necessary information is provided.</p>
<p>4.3. The Customer undertakes:</p>
<p>4.3.1. Timely and fully pay the cost of the Services rendered to the Contractor in the manner, within the time limits and in the amount established by this Agreement.</p>
<p>4.3.2. Provide the Contractor with all the information and data necessary to fulfill his obligations under this Agreement.</p>
<p>4.3.3. Do not disclose confidential information and other data provided by the Contractor in connection with the execution of this Agreement.</p>
<p>4.3.4. Do not compete with the Contractor in any areas, be respectable, do not interfere in the internal affairs of the Contractor, do not take measures that directly or indirectly worsen the organization of work of the Contractor and its affiliates.</p>
<p>4.3.5. Apply for services, including additional ones, directly at the contacts listed on the site. Do not create conflicts, do not mislead the Contractor.</p>
<p>4.3.6. Do not use agents, suppliers, assistants, employees, proxies of the Contractor for their own purposes without the prior consent of the Contractor.</p>
<p>4.3.7. The Customer is obliged to provide all the necessary documents for the performance of work / provision of Services to the Contractor. In case of failure to provide documents, failure to provide answers to the request of the Contractor within 120 calendar days from the date of payment of the invoice, the Contractor shall not be liable for the performance of work / provision of Services and payment for the return of the invoice and / or offset through other works / services is not subject to. The work/service is considered completed.</p>
<p>4.4. The Customer has the right:</p>
<p>4.4.1. Require the Contractor to provide information on organizing and ensuring the proper provision of the Services.</p>
<p>4.4.2. Require proper and timely provision of the Services by the Contractor.</p>
<p>5. TERMS OF PROVISION OF SERVICES</p>
<p>5.1. The terms for the provision of Services are negotiated individually with the Customer and begin to be calculated from the moment the Contractor receives all the required amount of information from the Customer, documents and payment in the manner prescribed by paragraphs. 6.3., 6.4 of this Agreement.</p>
<p>6. COST OF SERVICES AND PROCEDURE OF PAYMENTS</p>
<p>6.1. The cost of the Services provided is determined by the Contractor in accordance with the issued invoice, which provides for a certain list of Services, and the Customer undertakes to pay for these Services in accordance with the issued invoice.</p>
<p>6.2. All services are indicated in the currency of the invoice.</p>
<p>6.3. Payment for the Services under this Agreement is carried out on the basis of 100% prepayment and in the manner established by this Agreement.</p>
<p>6.4. The Customer is solely responsible for the correctness of payments made by him. The moment of payment is considered the receipt of funds to the account of the Contractor.</p>
<p>6.5. The Contractor reserves the right to change the Tariffs at its own discretion, except for already concluded agreements.</p>
<p>6.6. The final cost of the Service is determined by the Contractor depending on the task of the Customer. If additional provision of the Services is required, the cost will be increased accordingly.</p>
<p>6.7. The commission for the Services provided by the Contractor is non-refundable and does not include the Bank&apos;s Tariffs.</p>
<p>6.8. If the Customer provides incomplete, inaccurate or contradictory information that affected the outcome of the provision of the Services to the Customer, the Contractor reserves the right not to return to the Customer the payment made for these Services.</p>
<p>7. RESPONSIBILITIES OF THE PARTIES</p>
<p>7.1. The Parties are responsible for non-fulfillment or improper fulfillment of their obligations under the Agreement in accordance with the current legislation of Ukraine.</p>
<p>7.2. The Contractor is responsible for the timeliness of the services provided when the Customer fulfills the established Terms of Use posted on the website&nbsp;</p>
<p><a href="https://fmc-dev.com/offer">https://fmc-dev.com/offer</a></p>
<p>7.3. The Contractor is not responsible for non-receipt of the Services by the Customer, and the payment made in this case is not refundable and is not transferred to other services, in the following cases:</p>
<p>7.3.1. The e-mail address specified by the Customer is not available at the time of the provision of the Service.</p>
<p>7.3.2. The Customer cannot receive paid Services due to technical or other problems that he has encountered.</p>
<p>7.4. The Contractor is in no way responsible for the actions and / or acts of inaction of the Customer, carried out by him before or after the provision of the Services.</p>
<p>8. SPECIAL TERMS AND CONDITIONS OF DISPUTES</p>
<p>8.1. This Public Offer Agreement has the force of an act on the provision of services. Acceptance is made without signing the relevant act. Services are considered to be rendered properly and in full, if the Customer has not filed a claim within three days from the date of provision of the service.</p>
<p>8.2. Claims of the Customer for the Services provided are accepted by the Contractor for consideration by email within 3 (working) days from the moment the dispute arises.</p>
<p>8.3. The Contractor and the Customer, taking into account the nature of the service provided, undertake, in case of disputes and disagreements related to the provision of services, to apply the pre-trial procedure for resolving the dispute.</p>
<p>8.4. Issues arising from the interpretation and application of this Agreement and not regulated by it are regulated on the basis of the current legislation of Ukraine. The Parties will make every reasonable effort to resolve through negotiations any disputes arising from this Agreement, in connection with it, or with its violation.</p>
<p>9. FORCE MAJEURE</p>
<p>9.1. The Parties are released from liability for full or partial failure to fulfill obligations under the Agreement if the failure to fulfill obligations was the result of force majeure, namely: fire, flood, earthquake, strike, war, actions of public authorities or other circumstances beyond the control of the Parties.</p>
<p>9.2. The Party that cannot fulfill its obligations under the Agreement must promptly, but no later than 10 calendar days after the onset of force majeure circumstances, notify the other Party in writing with the provision of supporting documents issued by the competent authorities.</p>
<p>9.3. The Parties acknowledge that the insolvency of the Parties is not a force majeure event.</p>
<p>10. CONCLUSION, AMENDMENT, TERMINATION OF THE AGREEMENT</p>
<p>10.1. The moment of conclusion of this Agreement is considered the moment the payment is credited to the Contractor&apos;s settlement account.</p>
<p>10.2. The Customer concludes the Offer Agreement voluntarily, while the Customer:</p>
<p>a) fully familiarized with the terms of the Offer;</p>
<p>b) fully understands the subject of the Offer and the terms of the Offer Agreement;</p>
<p>c) fully understands the meaning and consequences of their actions in relation to the conclusion and execution of the Offer Agreement.</p>
<p>10.3. The Customer has all the rights and powers necessary for the conclusion and execution of the Offer Agreement.</p>
<p>10.4.The Customer has the right to unilaterally refuse the Services of the Contractor at any time. In case of unilateral refusal of the Customer from the services of the Contractor, the payment made is not refundable.</p>
<p>10.5. In case if one of the Parties of the Agreement wishes to terminate the Agreement before the expiration of the period for the provision of Services agreed by the Parties, but not in accordance with the terms of termination of the agreement provided by the Parties, the other Party has the right to demand that the Party wishing to terminate the Agreement pay a fee for early cancellation of the agreement (termination fee) commensurate with the cost and duration of the provision of Services upon prior agreement with the other Party. The minimum fee for early termination of the provision of Services may be preliminarily provided by the Parties when agreeing on the Services provided.</p>
<p>10.6. The Contractor has the right to terminate the Agreement with the Customer if the Customer&apos;s actions could potentially or caused direct or indirect damage to the Contractor, his partners and (or) other companies and persons associated with the Contractor and (or) his (their) employees.</p>
<p>10.7. In case of causing damage to the Contractor, its partners and (or) other companies and persons associated with the Contractor and (or) his (their) employees, the Customer undertakes to pay compensation in monetary terms commensurate with the damage caused.</p>
<p>10.8. Concerning all issues not regulated by this Agreement, the parties are guided by the current legislation of Ukraine.</p>
    </div>
  )
}

export default OfferEN