import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './services-block.css';
import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
    en: {
        section_title: 'Services',
        items: [
            {
                title: 'Back-End',
                description: 'We work with PHP, JS and frameworks based on them.'
            },
            {
                title: 'Front-End',
                description: 'We use modern technologies - HTML5, CSS3, SASS, Gulp, Bootstrap, React, Vue, jQuery ...'
            },
            {
                title: 'Data Base',
                description: 'We have experience in designing and developing databases with high productivity: MySQL, PostgreSQL, Firebase, Microsoft SQL ...'
            },
            {
                title: 'API',
                description: 'We have experience in integration various of services, such as: payment, geolocation, cloud systems, etc. We also develop our own REST-API.'
            }
        ]
    },
    ru: {
        section_title: 'Услуги',
        items: [
            {
                title: 'Back-End',
                description: 'Мы работаем с PHP, JS и фреймворками на их основе.'
            },
            {
                title: 'Front-End',
                description: 'Используем современные технологии - HTML5, CSS3, SASS, Gulp, Bootstrap, React, Vue, jQuery ...'
            },
            {
                title: 'Data Base',
                description: 'Есть опыт в проектировании и разработке баз данных с высокой продуктивностью: MySQL, PostgreSQL, Firebase, Microsoft SQL ...'
            },
            {
                title: 'API',
                description: 'Интегрируем различные сервисы, такие как: платежные, геолокационные, облачные системы, и т.д. А также разрабатываем свои REST-API.'
            }
        ]
    },
    de: {
        section_title: 'Leistungen',
        items: [
            {
                title: 'Back-End',
                description: 'Wir arbeiten mit PHP, JS und darauf basierenden Frameworks.'
            },
            {
                title: 'Front-End',
                description: 'Wir verwenden ausschließlich neueste Technologien - HTML5, CSS3, SASS, Gulp, Bootstrap, React, Vue, jQuery….'
            },
            {
                title: 'Data Base',
                description: 'Wir haben Erfahrung im Entwerfen und Entwickeln von Datenbanken mit hoher Produktivität: MySQL, PostgreSQL, Firebase, Microsoft SQL ….'
            },
            {
                title: 'API',
                description: 'Wir haben Erfahrung in der Integration verschiedener Dienste, wie z. B. Zahlung, Geolocation, Cloud-Systeme usw. Wir entwickeln auch unsere eigene REST-API.'
            }
        ]
    }
});

function ServicesBlock(props) {

    //componentDidUpdate() {
    //    let name = this.props.linkItem.href+"-id";
    //    console.log(name);
    //    scroller.scrollTo(name, {
    //        duration: 800,
    //        delay: 0,
    //        smooth: 'easeInOutQuart'
    //    });
    //}


    //console.log(this.props.linkItem);
    strings.setLanguage(props.lang);
    return (
        <Container id="services" className="ServicesBlock  ">
            <div className="header-title">
                <h2>{strings.section_title}</h2>
            </div>
            <Row className="services g-margin-s">
                {
                    strings.items.map((item, index) => {
                        return (
                            <Col key={index} sm="12" md="6" lg="6" xl="6" className="wrapper-services">
                                <div className="services-item">
                                    <h3 className="title">{item.title}</h3>
                                    <div className="description">{item.description}</div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )

}

export default ServicesBlock;