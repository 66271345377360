import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap'
import LocalizedStrings from 'react-localization'
// img
import './services-img.css'
// animation
import WebAnimation from './ServicesImgAnimation/WebAnimation'
import SeoAnimation from './ServicesImgAnimation/SeoAnimation'
import DesignAnimation from './ServicesImgAnimation/DesignAnimation'

const strings = new LocalizedStrings({
  en: {
    section_title: 'what we do',
    items: [
      {
        title: 'WEB development',
        description: [
          'Corporate sites',
          'Individual solutions',
          'Sites aggregators',
          'Internet shops',
          'Landing Pages',
          'Marketplaces',
          'Parsers'
        ]
      },
      {
        title: 'Promotion',
        description: [
          'Search engines optimization',
          'Site speed optimization',
          'Promotion in search engines',
          'Promotion in social networks',
          'Contextual advertising'
        ]
      },
      {
        title: 'Design',
        description: [
          'Logo',
          'Corporate Identity',
          'Leaflets',
          'Posters',
          'Magazines'
        ]
      }
    ]
  },
  ru: {
    section_title: 'что мы делаем',
    items: [
      {
        title: 'WEB разработка',
        description: [
          'Корпоративные сайты',
          'Индивидуальные решения',
          'Сайты агрегаторы',
          'Интернет магазины',
          'Landing Pages',
          'Маркетплейсы',
          'Парсеры'
        ]
      },
      {
        title: 'Продвижение',
        description: [
          'Оптимизация под поисковики',
          'Оптимизация скорости загрузки',
          'Продвижение в поисковиках',
          'Продвижение в соц-сетях',
          'Контекстная реклама'
        ]
      },
      {
        title: 'Дизайн',
        description: [
          'Логотип',
          'Фирменный стиль',
          'Листовки',
          'Плакаты',
          'Журналы'
        ]
      }
    ]
  },
  de: {
    section_title: 'was wir tun',
    items: [
      {
        title: 'WEB Entwicklung',
        description: [
          'Unternehmensseiten',
          'Individuelle Lösungen',
          'Online-Marktplätze',
          'Site-Aggregatoren',
          'Landing Pages',
          'Internetshops',
          'Parsers'
        ]
      },
      {
        title: 'Promotion',
        description: [
          'Suchmaschinenoptimierung',
          'Optimierung der Ladegeschwindigkeit',
          'Suchmaschinenwerbung',
          'Werbung in sozialen Netzwerken',
          'Kontextbezogene Werbung'
        ]
      },
      {
        title: 'DESIGN',
        description: [
          'Logo',
          'Corporate Design',
          'Flyer',
          'Poster',
          'Zeitschriften'
        ]
      }
    ]
  }
})

function ServicesImg(props) {

  let WebAnimationRef = useRef()
  let SeoAnimationRef = useRef()
  let DesignAnimationRef = useRef()


  useEffect(() => {

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])



  const [webAnimation, setWebAnimation] = useState('')
  const [seoAnimation, setSeoAnimation] = useState('')
  const [designAnimation, setDesignAnimation] = useState('')



  const handleScroll = () => {
    let clientHeight = (document.documentElement.clientHeight / 100) * 70
    let offsetWebAnimation = WebAnimationRef.current.getBoundingClientRect().top
    let offsetSeoAnimation = SeoAnimationRef.current.getBoundingClientRect().top
    let offsetDesignAnimation = DesignAnimationRef.current.getBoundingClientRect().top

    if (offsetWebAnimation < clientHeight) {
      setWebAnimation('animation')
    } else {
      setWebAnimation('')
    }
    if (offsetSeoAnimation < clientHeight) {
      setSeoAnimation('seo-animation-start')
    }
    if (offsetDesignAnimation < clientHeight) {
      setDesignAnimation('design-animation-start')
    }
  }


  strings.setLanguage(props.lang)

  return (
    <section className='ServicesImg g-margin-xl' onScroll={handleScroll}  >
      <Container>
        <div className="header-title">
          <h2>{strings.section_title}</h2>
        </div>
        <Row>
          <Col md='12'>
            <Row className='item'>
              <Col className='animation_block' md='6' sm='12' >
                <div
                  ref={WebAnimationRef}
                  className={`${webAnimation} animation_wrap`}
                >
                  <div className='title'>
                    <h3>{strings.items[0].title}</h3>
                  </div>
                  <WebAnimation />
                </div>
              </Col>
              <Col className='info_block ' md='6' sm='12'  >
                <div className='description web'>
                  {strings.items[0].description.map((item, index) => {
                    return <p key={index}>{item}</p>
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <Row className='item'>
              <Col className='animation_block' md='6' sm='12' >
                <div
                  ref={SeoAnimationRef}
                  className={`${seoAnimation} animation_wrap`}
                >
                  <div className='title'>
                    <h3>{strings.items[1].title}</h3>
                  </div>
                  <SeoAnimation />
                </div>
              </Col>
              <Col className='info_block ' md='6' sm='12'  >
                <div className='description seo'>
                  {strings.items[1].description.map((item, index) => {
                    return <p key={index}>{item}</p>
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <Row className='item'>
              <Col className='animation_block' md='6' sm='12'>
                <div
                  ref={DesignAnimationRef}
                  className={`${designAnimation} animation_wrap`}
                >
                  <div className='title'>
                    <h3>{strings.items[2].title}</h3>
                  </div>
                  <DesignAnimation />
                </div>
              </Col>
              <Col className='info_block ' md='6' sm='12'  >
                <div className='description des'>
                  {strings.items[2].description.map((item, index) => {
                    return <p key={index}>{item}</p>
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )

}

export default ServicesImg


