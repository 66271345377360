import React from 'react'
import Nav from '../../Main/Home/Header/Nav'
import FooterComp from '../../FooterComp/FooterComp'
import AnotherProjects from '../AnotherProjects/AnotherProjects'
// style

import './carenty.scss'

import image1 from '../../../img/projects/carenty/Frame 1880.png'
import image2 from '../../../img/projects/carenty/Group 1950.png'
import image3 from '../../../img/projects/carenty/image 303.png'
import image4 from '../../../img/projects/carenty/image 306.png'
import image5 from '../../../img/projects/carenty/image 30.png'
import image6 from '../../../img/projects/carenty/image 304.png'
import image7 from '../../../img/projects/carenty/image 305.png'
import image8 from '../../../img/projects/carenty/3.png'
import image9 from '../../../img/projects/carenty/1.png'
import image10 from '../../../img/projects/carenty/Group 1951.png'
import image11 from '../../../img/projects/carenty/26c12e63f2 1.png'
import image12 from '../../../img/projects/carenty/8100d511.png'
import image13 from '../../../img/projects/carenty/image 307.png'
import image14 from '../../../img/projects/carenty/cfe37e904f 1.png'




const Carenty = (props) => {
  return (
    <div className='carenty'>
      <Nav changeLang={props.changeLang} lang={props.lang} />
      <div className="carenty-main">
        <div className="container">
          <div className="carenty-main__inner">
            <div className="carenty-main__image">
              <img src={image1} alt="" className="carenty-main__img" />
            </div>
            <div className="carenty-main__content">
              <div className="carenty-main__title ">
                <p>
                  Carenty
                </p>
              </div>
              <div className="carenty-main__text"> <p>
                Carenty - SaaS платформа для аренды автомобилей построенная
                на модели Car-Sharing. Для владельца авто сервис дает возможность заработать на нем. А клиент который хочет арендовать авто может сделать это без сложностей присущих классическим брокерам,
                без скрытых платежей.</p>
              </div>
              <div className="carenty-main__button">
                Перейти на сайт
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carenty-project g-margin-xl">
        <div className="container">
          <div className="carenty-project__title title g-margin-xs">О проекте</div>
          <div className="carenty-project__text text g-margin-m">Главным преимуществом сайта есть то, что клиент, сумеет найти для себя авто в удобном для него местоположении, максимально близко к его локации. На платформе могут сдавать в аренду не только физические лица, а и юридические лица (бизнесы которые имеют собственный автопарк). Несмотря на это, физические лица могут конкурировать с авто-магнатами по цене, так как им не нужно платить зарплаты сотрудникам и арендную плату за свои офисы. Владельцы автомобилей имеют возможность абсолютно бесплатно размещать объявления об аренде, а другие пользователи, благодаря удобной фильтрации и интерактивной карте, выбирают автомобиль под свои индивидуальные нужды в удобной для себя геолокации.
          </div>
          <div className="carenty-project__image g-margin-m">
            <img src={image2} alt="" />
          </div>
          <div className="carenty-project__texts g-margin-xl">
            <div className="carenty-project__text text">Главной целью проекта является распространение модели  “Car sharing”. Особенность “Carenty” - это то, что клиенты не платят комиссию за размещение объявлений. Также большим плюсом является то, что все отзывы на сайте - правдивые, так как их могут оставлять только авторизованные пользователи, которые воспользовались услугой сервиса и успешно завершили ее. Это очень привлекает клиентов, и дает большие привилегии ресурсу, по сравнению с конкурентами.
            </div>
            <div className="carenty-project__text text">Команда FMC-dev с клиентом данного проекта с самого начала. И за почти 5 лет уже стали друзьями.
              Изначально клиент пришел к нам с готовым дизайном, он искал команду которая справится с таким объемным проектом. Забегая наперед скажу, что мы даже больше чем справились, мы еще и предлагали хорошие идеи, которые хорошо отразились на популярности сервиса. Об этом далее я расскажу.
            </div>
          </div>
        </div>
      </div>
      <div className="carenty-aim">
        <div className="container">
          <div className="carenty-aim__title title">Цели проекта</div>
          <div className="carenty-aim__list g-margin-xl">
            <div className="carenty-aim__box">
              <div className="carenty-aim__item">
                <div className="carenty-aim__image">
                  <img src={image3} alt="" />
                </div>
                <div className="carenty-aim__text">
                  Реализовать систему подбора автомобиля взаимодействуя с интерактивной картой. А также множества других страниц сайта.
                </div>
              </div>
              <div className="carenty-aim__item">
                <div className="carenty-aim__image">
                  <img src={image4} alt="" />
                </div>
                <div className="carenty-aim__text">
                  Создать административную панель для операторов сервиса, чтобы те могли отслеживать все процессы сайта и проводить модерацию.
                </div>
              </div>
              <div className="carenty-aim__item">
                <div className="carenty-aim__image">
                  <img src={image5} alt="" />
                </div>
                <div className="carenty-aim__text">
                  Создать удобную форму общения клиентов, чтобы они не переходили в другие мессенджеры. Сделать платформу для живого общения.
                </div>
              </div>
            </div>
            <div className="carenty-aim__box">
              <div className="carenty-aim__item">
                <div className="carenty-aim__image">
                  <img src={image6} alt="" />
                </div>
                <div className="carenty-aim__text">
                  Разработать личный кабинет для оформления и управления арендой автомобиля.
                </div>
              </div>
              <div className="carenty-aim__item">
                <div className="carenty-aim__image" >
                  <img src={image7} alt="" />
                </div>
                <div className="carenty-aim__text">
                  Проработать дизайн с учетом последних исследований в области UX.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carenty-team g-margin-xl">
        <div className="container">
          <div className="carenty-team__title">Команда</div>
          <div className="carenty-team__content">
            <div className="carenty-team__number">
              <div className="carenty-team__image">
                <img src={image8} alt="" className="carenty-team__img" />
              </div>
              <div className="carenty-team__text">Frontend разработчика</div>
            </div>
            <div className="carenty-team__number">
              <div className="carenty-team__image">
                <img src={image9} alt="" className="carenty-team__img" />
              </div>
              <div className="carenty-team__text">Backend разработчик</div>
            </div>
            <div className="carenty-team__number">
              <div className="carenty-team__image">
                <img src={image9} alt="" className="carenty-team__img" />
              </div>
              <div className="carenty-team__text">QA специалист</div>
            </div>
            <div className="carenty-team__number">
              <div className="carenty-team__image">
                <img src={image9} alt="" className="carenty-team__img" />
              </div>
              <div className="carenty-team__text">
                Designer
              </div>
            </div>
            <div className="carenty-team__number">
              <div className="carenty-team__image">
                <img src={image9} alt="" className="carenty-team__img" />
              </div>
              <div className="carenty-team__text">

                Project Manager
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carenty-work g-margin-xl">
        <div className="container">
          <div className="carenty-work__title title g-margin-xs">Работа проделанная нами:</div>
          <div className="carenty-work__texts text g-margin-m">
            <p>Прежде чем приступить к такой масштабной работе, мы обсудили с клиентом все его пожелания и требования, с целью завершить проект вовремя и не тратить время на большое количество переработок в будущем. Такой подход дает нам возможность завершить разработку к поставленным срокам.
            </p>
            <p>
              Нашей командой было принято решение полностью модернизировать дизайн сайта. Поэтому в первую очередь, к работе приступил дизайнер. Им было предложено несколько вариантов оформления платформы. Особое внимание уделялось таким страницам как - личный кабинет и чат клиентов, с целью сделать все максимально удобным в использовании.
            </p>
            <p>
              Процесс разработки был интерактивным, с планированием спринтов и участием клиента. Поспринтовый подход к разработке дизайна позволил заказчику изменять и дополнять требования к платформе в ходе проектирования.
            </p>
            <p>
              Для разработки была предложена - технология React.js для фронтенд части, так как мы понимали, что данный проект будет развиваться и масштабироваться в будущем.
            </p>
          </div>
        </div>
        <div className="carenty-work__images g-margin-xl">
          <img src={image10} alt="" />
        </div>
        <div className="container">
          <ul className="carenty-work__list title g-margin-xs">Основными метриками качества фронтенд разработки были установлены:
            <li className="carenty-work__item text">отзывчивость интерфейса, в частности на мобильных устройствах </li>
            <li className="carenty-work__item text">показатель PageSpeed Insights;</li>
            <li className="carenty-work__item text">размер DOM-дерева;</li>
            <li className="carenty-work__item text">суммарный размер загружаемой страницы и в особенности размер JavaScript библиотек.</li>
          </ul>
          <div className="carenty-work__text text g-margin-xl">
            Была разработана удобная административная панель для заказчика. С помощью которой он имеет доступ ко всем функциям системы, что дает ему возможность контролировать все процессы и проводить аналитику.
          </div>
        </div>
      </div>
      <div className="carenty-timing g-margin-xl">
        <div className="container">
          <div className="carenty-timing__title title g-margin-xs">Сроки:</div>
          <div className="carenty-timing__text text">Были поставлены сжатые сроки для реализации MVP, так как тогда было очень подходящее время для запуска данного сервиса. Перед нами стояла задача реализовать MVP за 6 месяцев и мы с этим справились. Это одна из многих причин, почему уже 5-ый год данный клиент работает с нами и доволен результатами нашей работы.
          </div>
        </div>
      </div>
      <div className="carenty-results g-margin-xl">
        <div className="container">
          <div className="carenty-results__title title g-margin-xs">Результаты: </div>
          <div className="carenty-results__text text g-margin-xs">По завершению нашей работы, “Carenty” приобрел модернизированный дизайн. Был разработан удобный личный кабинет для пользователей, а главное такие механизмы, которые и по сей день дают прирост пользователей. Структура сайта получилась удобной в использовании, а также мы разработали мобильную версию сайта, что дает ему возможность иметь вид мобильного приложения. </div>
          <div className="carenty-results__image g-margin-m">
            <img src={image11} alt="" />
          </div>
          <div className="carenty-results__text text g-margin-xs">Была подключена интерактивная карта, с помощью которой пользователю удобно агрегировать результаты поиска и дается возможность подобрать автомобиль по всем его нуждам.
          </div>
          <div className="carenty-results__image g-margin-m">
            <img src={image12} alt="" />
          </div>
          <div className="carenty-results__text text g-margin-xs">Так как мы имеем большой опыт работы с сайтами такого типа как Carenty, наша команда вносила много предложений, которые позитивно влияли на посещения сайта и его популярность. Вследствие чего увеличилось число пользователей и объявлений на сайте.
          </div>
          <div className="carenty-results__image g-margin-m">
            <img src={image13} alt="" />
          </div>
          <div className="carenty-results__text text g-margin-xs">Также благодаря тому, что мы внедрили функционал, который позволяет оставлять только правдивые отзывы и не дает возможность накручивать их, у клиентов “Carenty”, появилось больше доверия, что есть большим преимуществом по сравнению с конкурентами.
          </div>
          <div className="carenty-results__image g-margin-m">
            <img src={image14} alt="" />
          </div>
          <div className="carenty-results__text text ">Все обновления положительно отражались и в цифрах.  К примеру количество посещений пользователей выросли в с 2 тыс пользователей за квартал до 13 тыс пользователей. Если сравнить данные Яндекс Метрики, собранные за август 2020-го со старого сайта, и за август 2021-го уже с нового, то видно, что показатель отказов снизился на 16,7%.
          </div>
        </div>
      </div>
      <AnotherProjects />
      <FooterComp />
    </div>
  )
}

export default Carenty
