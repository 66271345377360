import React from 'react'

const OfferRU = () => {
  return (
    <div>
      <div className='header-title'>
        <h2>ПУБЛИЧНЫЙ ДОГОВОР-ОФЕРТА ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ</h2>
      </div>
<p>1.1. Данный документ является Публичной Офертой компании FMC-dev (физическое лицо предприниматель Мельничук Фридрих Александрович, запись в Едином государственном реестре №20260000000041685 от 14.02.2018) и (или) ее агенты/партнеры (если они указаны в счетах на услуги), в дальнейшем именуемого &laquo;Исполнитель&raquo; и содержит все существенные условия оказания информационно-консультационных услуг.</p>
<p>1.2. Публичная Оферта является официальным документом и опубликована на сайте Исполнителя по адресу:</p>
<p><a href="https://fmc-dev.com/offer">https://fmc-dev.com/offer</a></p>
<p>1.3. В случае оплаты услуг, юридическое или физическое лицо тем самым подтверждает принятие и акцепт изложенных ниже условий Оферты и становится Заказчиком, а Исполнитель и Заказчик совместно &mdash; Сторонами договора Оферты.</p>
<p>1.4. Договор Оферты не требует скрепления печатями и подписания Заказчиком, сохраняя при этом полную юридическую силу.</p>
<p>1.5. В связи с вышеобъявленным, Заказчик обязан внимательно ознакомится с текстом данной Оферты и в случае несогласия с условиями и правилами - отказаться от заключения договора Оферты и использования услуг Исполнителя.</p>
<p>2. ПРЕДМЕТ ДОГОВОРА ОФЕРТЫ</p>
<p>2.1. В соответствии с условиями настоящего Договора, Исполнитель обязуется оказать Услуги указанные в выставленном инвойсе, а Заказчик обязуется оплатить эти Услуги в соответствии с выставленным инвойсом.</p>
<p>3. АКЦЕПТ ОФЕРТЫ И ЗАКЛЮЧЕНИЕ ДОГОВОРА ОФЕРТЫ</p>
<p>3.1. Заказчик производит Акцепт Оферты путем предоплаты Услуг Исполнителя, в отношении которых заключается Договор Оферты. Акцептирование Заказчиком настоящего Договора означает, что он полностью согласен со всеми положениями настоящего Договора.</p>
<p>3.2. Осуществляя Акцепт Оферты в порядке, определенном п. 3.1 Договора, Заказчик гарантирует, что ознакомлен, согласен, полностью и безоговорочно принимает все условия Договора в том виде, в каком они изложены.</p>
<p>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>
<p>4.1. Исполнитель обязуется:</p>
<p>4.1.1. Организовать и обеспечить надлежащее оказание Услуг.</p>
<p>4.1.2. Использовать все личные данные и иную конфиденциальную информацию о Заказчике только для оказания Услуг, не передавать и не показывать третьим лицам, находящуюся у него документацию и информацию о Заказчике.</p>
<p>4.1.3. Давать устные и письменные консультации по дополнительным вопросам Заказчика. Объем и сроки консультирования, а также форма консультации определяется в каждом конкретном случае Исполнителем самостоятельно.</p>
<p>4.1.4. Клиент обязан предоставить все документы, и произвести все соответствующие действия, которые лежат в плоскости KYC, DD-процессов на него и аффилированных с ним лиц, если эта обязательная норма установлена страной, где клиент приобретает услуги.</p>
<p>4.2. Исполнитель вправе:</p>
<p>4.2.1. В одностороннем порядке определять стоимость всех предоставляемых Услуг и изменять условия данного Договора, кроме уже заключенных договоров.</p>
<p>4.2.2. Самостоятельно определять формы и методы оказания Услуг исходя из требований законодательства, технических возможностей, а также конкретных условий Договора с учетом пожеланий Заказчика.</p>
<p>4.2.3. Пользоваться услугами любых физических и юридических лиц, в целях своевременного и качественного исполнения обязательств по Договору. Самостоятельно определять состав специалистов, оказывающих Услуги, и по своему усмотрению распределять между ними работу.</p>
<p>4.2.4. Требовать оплату за оказанные или оказываемые Услуги.</p>
<p>4.2.5. Отказать Заказчику в предоставлении услуги в случае неоплаты (неполной оплаты) за услуги в установленные сроки, при несвоевременном предоставлении заявки на оказание услуги.</p>
<p>4.2.6. Получать от Заказчика любую информацию, необходимую для выполнения своих обязательств по Договору. В случае не предоставления либо неполного или неверного предоставления Заказчиком информации Исполнитель вправе приостановить исполнение своих обязательств по Договору до предоставления необходимой информации.</p>
<p>4.3. Заказчик обязуется:</p>
<p>4.3.1. Своевременно и полностью оплачивать Исполнителю стоимость оказываемых Услуг в порядке, в сроки и размере, установленных настоящим Договором.</p>
<p>4.3.2. Предоставить Исполнителю все сведения и данные, необходимые для выполнения своих обязательств по настоящему Договору.</p>
<p>4.3.3. Не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в связи с исполнением настоящего Договора.</p>
<p>4.3.4. Не конкурировать с Исполнителем ни в каких сферах, быть добропорядочным, не вмешиваться во внутренние дела исполнителя, не принимать мер, которые прямо или косвенно ухудшающих организацию труда у Исполнителя и аффилированных с ним лиц.</p>
<p>4.3.5. Обращаться за услугами, в том числе дополнительными, непосредственно по контактам, указанным на сайте. Не создавать конфликтов, не вводить исполнителя в заблуждение.</p>
<p>4.3.6. Не использовать агентов, поставщиков, помощников, сотрудников, доверенных лиц Исполнителя в своих целях без предварительного согласия Исполнителя.</p>
<p>4.3.7. Заказчик обязан предоставить все необходимые документы для выполнения работ/предоставление услуг Исполнителю. В случае не предоставления документов, не предоставления ответов на запрос Исполнителя в течении 120 календарных дней с момента оплаты инвойса, Исполнитель снимает с себя обязательства по выполнению работ/предоставлению услуг и оплата по произведенному инвойсу возврату и/или зачету в счет иных работ/услуг не подлежит. Работа/услуга считается исполненной.</p>
<p>4.4. Заказчик вправе:</p>
<p>4.4.1. Требовать от Исполнителя предоставления информации по вопросам организации и обеспечения надлежащего оказания Услуг.</p>
<p>4.4.2. Требовать надлежащего и своевременного оказания Услуг Исполнителем.</p>
<p>5. СРОКИ ОКАЗАНИЯ УСЛУГ</p>
<p>5.1. Сроки оказания Услуг обговариваются индивидуально с Заказчиком и начинают исчисляться с момента получения Исполнителем всего требуемого объема информации от Заказчика, документов и оплаты в порядке, установленном пп. 6.3., 6.4 данного Договора.</p>
<p>6. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</p>
<p>6.1. Стоимость предоставляемых Услуг определяется Исполнителем в соответствии с выставленным инвойсом,в котором предусмотрен определенный перечень Услуг, а Заказчик обязуется оплатить эти Услуги в соответствии с выставленным инвойсом.</p>
<p>6.2. Все услуги указываются в валюте инвойса.</p>
<p>6.3. Оплата Услуг по настоящему Договору осуществляется на основе 100%-ной предоплаты и в порядке, устанавливаемом настоящим Договором.</p>
<p>6.4. Заказчик самостоятельно несет ответственность за правильность производимых им платежей. Моментом оплаты считается поступление средств на расчетный счет Исполнителя.</p>
<p>6.5. Исполнитель оставляет за собой право изменять Тарифы по своему усмотрению, кроме уже заключенных договоров.</p>
<p>6.6. Окончательную стоимость Услуги Исполнитель устанавливает в зависимости от задания Заказчика. В случае если требуется дополнительное предоставление Услуг, то стоимость будет увеличена соответственно.</p>
<p>6.7. Комиссия за Услуги, предоставляемые исполнителем, возврату не подлежит и не включает в себя Тарифы банка.</p>
<p>6.8. В случае предоставления Заказчиком неполной, недостоверной или противоречивой информации, которая повлияла на итог предоставления Услуг Заказчику, Исполнитель оставляет за собой право не возвращать Заказчику оплату, произведенную за данные Услуги.</p>
<p>7. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
<p>7.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по Договору в соответствии с действующим законодательством Украины.</p>
<p>7.2. Исполнитель несет ответственность за своевременность предоставляемых услуг при выполнении Заказчиком установленных Условий использования, размещенных на сайте</p>
<p><a href="https://fmc-dev.com/offer">https://fmc-dev.com/offer</a></p>
<p>7.3. Исполнитель не несет ответственности за неполучение Заказчиком Услуг, а произведенная в данном случае оплата не возвращается и на другие услуги не переносится, в следующих случаях:</p>
<p>7.3.1. Указанный Заказчиком электронный адрес на момент оказания услуги недоступен.</p>
<p>7.3.2. Заказчик не может получить оплаченные услуги по причине возникших у него технических или иных проблем.</p>
<p>7.4. Исполнитель никоим образом не несет ответственность за действия и/или акты бездействия Заказчика, осуществленные им до или после оказания Услуг.</p>
<p>8. ОСОБЫЕ УСЛОВИЯ И ПОРЯДОК РАССМОТРЕНИЯ СПОРОВ</p>
<p>8.1. Настоящий Договор Публичной Оферты имеет силу акта об оказании услуг. Приемка производится без подписания соответствующего акта. Услуги считаются оказанными надлежащим образом и в полном объеме, если в течение трех дней с момента оказания услуги Заказчик не предъявил претензию.</p>
<p>8.2. Претензии Заказчика по предоставляемым Услугам принимаются Исполнителем к рассмотрению по электронной почте в течение 3-х (рабочих) дней с момента возникновения спорной ситуации.</p>
<p>8.3. Исполнитель и Заказчик, принимая во внимание характер оказываемой услуги, обязуются в случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный порядок урегулирования спора.</p>
<p>8.4. Вопросы, возникающие из толкования и применения настоящего Договора и не урегулированные им, регулируются на основании действующего законодательства Украины. Стороны приложат все разумные усилия для урегулирования путем переговоров любых споров, возникающих из настоящего Договора, в связи с ним, либо с его нарушением.</p>
<p>9. ФОРС-МАЖОР</p>
<p>9.1. Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по Договору в случае, если неисполнение обязательств явилось следствием действий непреодолимой силы, а именно: пожара, наводнения, землетрясения, забастовки, войны, действий органов государственной власти или других, не зависящих от Сторон обстоятельств.</p>
<p>9.2. Сторона, которая не может выполнить обязательства по Договору, должна своевременно, но не позднее 10 календарных дней после наступления обстоятельств непреодолимой силы, письменно известить другую Сторону с предоставлением обосновывающих документов, выданных компетентными органами.</p>
<p>9.3. Стороны признают, что неплатежеспособность Сторон не является форс-мажорным обстоятельством.</p>
<p>10. ЗАКЛЮЧЕНИЕ, ИЗМЕНЕНИЕ, РАСТОРЖЕНИЕ ДОГОВОРА</p>
<p>10.1. Моментом заключения данного Договора считается момент зачисления оплаты на расчетный счет Исполнителя.</p>
<p>10.2. Заказчик заключает Договор Оферты добровольно, при этом Заказчик:</p>
<p>а) полностью ознакомился с условиями Оферты;</p>
<p>б) полностью понимает предмет Оферты и условия Договора Оферты;</p>
<p>в) полностью понимает значение и последствия своих действий в отношении заключения и исполнения Договора Оферты.</p>
<p>10.3. Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и исполнения Договора Оферты.</p>
<p>10.4. Заказчик вправе в любое время в одностороннем порядке отказаться от Услуг Исполнителя. В случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата не возвращается.</p>
<p>10.5. В случае если одна из сторон договора пожелает расторгнуть договор до истечения оговоренного сторонами срока оказания услуг, но не в соответствии с условиями расторжения договора предусмотренными сторонами, другая сторона имеет право потребовать от стороны, желающей прекратить действие договора, произвести плату за досрочный отказ от договора (termination fee) соразмерную стоимости и сроку оказания услуг по предварительному согласованию с другой стороной. Минимальная плата за досрочный отказ от оказания услуг может быть предварительно предусмотрена сторонами при согласовании оказываемых услуг.</p>
<p>10.6. Исполнитель имеет право расторгнуть договор с Заказчиком в случае если действия Заказчика потенциально могут или нанесли прямой или косвенный ущерб Исполнителю, его партнёрам и (или) иным компаниям и лицам, связанным с Исполнителем и (или) его (их) работниками.</p>
<p>10.7. В случае нанесения своими действиями ущерба Исполнителю, его партнёрам и (или) иным компаниям и лицам, связанным с Исполнителем и (или) его (их) работниками Заказчик обязуется выплатить компенсацию в денежном эквиваленте соразмерном нанесенному ущербу.</p>
<p>10.8. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим законодательством Украины.</p>
    </div>
  )
}

export default OfferRU