import React, { useEffect } from 'react'

// style
import './workCart.css'


function WorkCart({ title, subtitle, text, technology, pdf, img, readMore }) {

    return (
        <div className="cart">

            <div className="cart__img">
                <a target='_blank' href={pdf}>
                    <img src={img} alt='FMC-dev' className='cart-image' />
                </a>
            </div>
            <div className="cart__description">
                <h1 className="cart__title">{title}</h1>
                <div className="cart__subtitle">
                    {
                        subtitle.map((item, id) => {
                            return (
                                <p key={id}>{item}</p>
                            )
                        })
                    }
                </div>
                <div className="cart__text">{text}</div>
                <div className="cart__technology">
                    {
                        technology.map((item, id) => {
                            return (
                                <div key={id}>{item}</div>
                            )
                        })
                    }
                </div>
                <div className="cart__link">
                    <a target='_blank' href={pdf}>{readMore}</a>
                </div>
            </div>

        </div>
    )
}
export default WorkCart