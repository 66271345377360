import React, { Component } from 'react';
import './bottonmore.css'
import LocalizedStrings from 'react-localization';



function BottonMore(props) {
    return (
        <div className='botton'>{props.children}</div>
    )
}

export default BottonMore