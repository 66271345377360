import React, { Component } from 'react'
import LocalizedStrings from 'react-localization'
import { useState } from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
// components

import FmcImg from '../../../../img/fmc_logo.svg'
// style
import './header.css'

const strings = new LocalizedStrings({
  en: {
    items: ['Website', 'design', 'and development'],
    itemsSmall: ['Website ', 'design'],
    itemBotton: ['Our Work'],

  },
  ru: {
    items: ['Дизайн', 'и разработка', 'сайтов'],
    itemsSmall: ['Разработка ', 'сайтов'],
    itemBotton: ['Наши работы']
  },
  de: {
    items: ['Webdesign', 'und Webseiten', 'Entwicklung'],
    itemsSmall: ['Entwicklung', 'von Websiten'],
    itemBotton: ['Unsere Werke'],
  },
});

const sites = [

  { title: 'Carenty', link: '/works', className: 'note-img-2' },
  {
    title: 'Co-Loader',
    link: '/works',
    className: 'note-img-1'
  },
  { title: 'Neitronik', link: '/works', className: 'note-img-3' },
  {
    title: 'Sawest Trade',
    link: '/works',
    className: 'note-img-4'
  },
  {
    title: 'Victoria Clinic',
    link: '/works',
    className: 'note-img-5'
  }
]

function Header(props) {

  const [value, setValue] = useState(10000)


  const currentFunction = currentSlide => {
    currentSlide === 1
      ? setValue(6000)
      : setValue(10000)
  }

  //ФУНКЦИЯ ЛОКАЛИЗАТОР
  strings.setLanguage(props.lang)


  let settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: value,
    afterChange: currentFunction,
    draggable: false,
    pauseOnHover: false
  }

  return (
    <header className='header g-margin-xl'>
      <div className='container'>
        <img src={FmcImg} alt='FMC-dev' className='big-logo' height="262" width="720" />
        <div className='header-desc'>
          <div className='header-desc__inner'>
            {strings.items.map((item, index) => {
              return (
                <span
                  key={index}
                  className='header-desc__item'
                  data-text={item}
                >
                  {item}
                </span>
              )
            })}
          </div>
        </div>
        <div className='header-laptop'>
          <div className='header-laptop__page-wrap'>
            <div className='header-laptop__page'>
              <Slider {...settings}>
                {sites.map((item, index) => {
                  return item.link === '#' ? (
                    <div
                      key={index}
                      className={item.className + ' laptop-inside'}
                    >
                      &nbsp;
                    </div>
                  ) : (
                    <a
                      href={item.link}
                      key={index}
                      title={item.title}
                      rel='noopener noreferrer'
                      className={item.className + ' laptop-inside'}
                    >
                      &nbsp;
                    </a>
                  )
                })}
              </Slider>
            </div>
          </div>
          <div className="header-laptop__button">
            {strings.itemBotton.map(item => {
              return (
                <Link to='/works'> <div className='header-laptop__link'>{item}</div></Link>
              )
            })}
          </div>
        </div>
      </div>

    </header >
  )

}

export default Header
