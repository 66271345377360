import React, { Component } from 'react'
import LocalizedStrings from 'react-localization'
import { fetch as fetchPolyfill } from 'whatwg-fetch'
import './footerform.css'
const strings = new LocalizedStrings({

  en: {
    form_title: [
      'Contact us for more details',
      'Contact us and together we will calculate the cost:'
    ],
    input_name: 'Your name',
    input_contact: 'Phone, e-mail or skype',
    input_comment: 'Comment',
    button_name: 'Submit',
    button_loading: 'Sending',
    form_validation: {
      name: 'Please enter your name',
      name_value: 'Only letters allowed!',
      name_length: 'Max 128 symbols',
      contacts: 'Please enter your Contacts',
      contacts_length: 'Max 128 symbols',
      comment_length: 'Max 1000 symbols',
      response_valid: 'Message has been sent',
      response_warning: 'Message in the waiting list',
      response_error: 'An error has occurred'
    }
  },
  ru: {
    form_title: [
      'Хотите узнать больше - свяжитесь с нами',
      'Свяжитесь с нами и мы вместе рассчитаем стоимость:'
    ],
    input_name: 'Ваше имя',
    input_contact: 'Телефон, e-mail или skype',
    input_comment: 'Комментарий',
    button_name: 'Отправить',
    button_loading: 'Отправка',
    form_validation: {
      name: 'Укажите, пожалуйста, Ваше имя',
      name_value: 'Допустимы только буквы!',
      name_length: 'Максимум 128 символов!',
      contacts: 'Укажите, пожалуйста, Ваши контактные данные',
      contacts_length: 'Максимум 128 символов!',
      comment_length: 'Максимум 1000 мимволов!',
      response_valid: 'Сообщение отправлено',
      response_warning: 'Сообщение в списке ожидания',
      response_error: 'Возникла ошибка'
    }
  },
  de: {
    form_title: [
      'Kontaktieren Sie uns für weitere Details.',
      'Kontaktieren Sie uns und wir berechnen gemeinsam die Kosten:'
    ],
    input_name: 'Ihr Name',
    input_contact: 'Telefon, E-Mail oder Skype',
    input_comment: 'Nachricht/Kommentar',
    button_name: 'Senden',
    button_loading: 'Absendung',
    form_validation: {
      name: 'Bitte geben Sie Ihren Namen ein',
      name_value: 'Nur Buchstaben erlaubt!',
      name_length: 'Maximal 128 Symbole',
      contacts: 'Bitte geben Sie Kontaktdaten ein',
      contacts_length: 'Maximal 128 Symbole',
      comment_length: 'Maximal 1000 Symbole',
      response_valid: 'Nachricht wurde gesendet',
      response_warning: 'Nachricht in der Warteliste',
      response_error: 'Ein Fehler ist aufgetreten'
    }
  }
})

class FooterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: new Date().getFullYear() + ' ',
      formNameValue: '',
      formErrorName: false,
      formErrorNameLength: false,
      formErrorNameValue: false,
      formContactValue: '',
      formErrorContact: false,
      formErrorContactLength: false,
      formCommentValue: '',
      formErrorComment: false,
      formErrorCommentLength: false,
      formSubmitLoading: false,
      requestMessageStatus: '',
      formLoadingDots: '',
      intervalId: '',
      formPlace: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.formLoadingDots.length === 4) {
      this.setState({ formLoadingDots: '' })
    } else if (prevState.formLoadingDots !== this.state.formLoadingDots) {
      this.setState({ formLoadingDots: this.state.formLoadingDots })
    }
  }
  componentDidMount() {
    this.setState({ formPlace: this.props.place });
  }

  handleDots = () => {
    let intervalId = setInterval(
      () =>
        this.setState({ formLoadingDots: this.state.formLoadingDots + '.' }),
      1000
    )
    this.setState({ intervalId: intervalId })
  }

  submitForm = e => {
    e.preventDefault()
    this.setState({ formNameValue: this.state.formNameValue })
    this.setState({ requestMessageStatus: '' })
    /* validation NAME */
    if (this.state.formNameValue.trim().length === 0) {
      this.state.formNameValue = ''
      let space = ''
      this.setState({ formNameValue: space })
      this.setState({ formErrorName: true })
      this.setState({ formErrorNameLength: false })
    }
    if (!this.state.formNameValue.trim().match(/^[а-яА-ЯёЁa-zA-Z\s]+$/)) {
      this.setState({ formErrorNameValue: true })
    }
    if (!this.state.formNameValue) {
      this.setState({ formErrorName: true })
      this.setState({ formErrorNameValue: false })
    }
    if (this.state.formNameValue.length > 128) {
      this.setState({ formErrorNameLength: true })
      this.setState({ formErrorNameValue: false })
    }
    /* validation CONTACTS  */
    if (this.state.formContactValue.trim().length === 0) {
      this.state.formContactValue = ''
      let space = ''
      this.setState({ formContactValue: space })
      this.setState({ formErrorContact: true })
      this.setState({ formErrorContactLength: false })
    }
    if (!this.state.formContactValue) {
      this.setState({ formErrorContact: true })
    }
    if (this.state.formContactValue.length > 128) {
      this.setState({ formErrorContactLength: true })
    }
    /* validation COMMENTS */
    if (!this.state.formCommentValue) {
      this.setState({ formErrorComment: true })
    }
    if (this.state.formCommentValue.length > 1000) {
      this.setState({ formErrorCommentLength: true })
    }
    if (
      !this.state.formNameValue ||
      !this.state.formContactValue ||
      this.state.formErrorNameLength ||
      this.state.formErrorNameValue ||
      this.state.formErrorContactLength ||
      this.state.formErrorCommentLength
    ) {
    } else {
      this.handleDots()
      this.setState({ formSubmitLoading: true })
      let apiUrl = '../../../mail/controller/save_form.php'
      let data = new FormData(e.target)
      data.append('formName', this.state.formPlace)
      fetchPolyfill(apiUrl, {
        method: 'POST',
        body: data
      })
        .then(response => response.json())
        .then(response => {
          setTimeout(
            function () {
              this.setState({ requestMessageStatus: response.code })
              this.setState({ formSubmitLoading: false })
              this.setState({ formNameValue: '' })
              this.setState({ formContactValue: '' })
              this.setState({ formCommentValue: '' })
              this.setState({ formLoadingDots: '' })
              clearInterval(this.state.intervalId)
            }.bind(this),
            3500
          )
        })
        .catch(error => {
          setTimeout(
            function () {
              this.setState({ requestMessageStatus: '404' })
              this.setState({ formSubmitLoading: false })
              this.setState({ formNameValue: '' })
              this.setState({ formContactValue: '' })
              this.setState({ formCommentValue: '' })
              this.setState({ formLoadingDots: '' })
              clearInterval(this.state.intervalId)
            }.bind(this),
            3500
          )
        })
    }
  }
  nameForm = e => {

    this.setState({ formErrorName: false })
    this.setState({ formNameValue: e.target.value })
    if (this.state.requestMessageStatus.length > 1) {
      this.setState({ requestMessageStatus: '' })
    }
    if (String(e.target.value).length > 128) {
      this.setState({ formErrorNameLength: true })
      this.setState({ formErrorNameValue: false })
    } else if (
      String(e.target.value).length > 0 &&
      !String(e.target.value)
        .trim()
        .match(/^[а-яА-ЯёЁa-zA-Z\s]+$/)
    ) {
      this.setState({ formErrorNameValue: true })
      this.setState({ formErrorNameLength: false })
      this.setState({ formErrorName: false })
    } else {
      this.setState({ formErrorNameLength: false })
      this.setState({ formErrorNameValue: false })
    }
  }
  contactFrom = e => {
    this.setState({ formErrorContact: false })
    this.setState({ formContactValue: String(e.target.value) })
    if (this.state.requestMessageStatus.length > 1) {
      this.setState({ requestMessageStatus: '' })
    }
    if (String(e.target.value).length > 128) {
      this.setState({ formErrorContactLength: true })
    } else {
      this.setState({ formErrorContactLength: false })
    }
  }
  commentForm = e => {
    this.setState({ formCommentValue: String(e.target.value) })
    if (this.state.requestMessageStatus.length > 1) {
      this.setState({ requestMessageStatus: '' })
    }
    if (String(e.target.value).length > 1000) {
      this.setState({ formErrorCommentLength: true })
    } else {
      this.setState({ formErrorCommentLength: false })
    }
  }

  render() {
    strings.setLanguage(this.props.lang)

    const {
      formErrorName,
      formErrorNameLength,
      formNameValue,
      formSubmitLoading,
      formErrorContact,
      formErrorContactLength,
      formContactValue,
      formCommentValue,
      requestMessageStatus,
      formErrorCommentLength,
      formLoadingDots,
      date,
      formErrorNameValue
    } = this.state

    const { submitForm, nameForm, contactFrom, commentForm } = this

    return (
      <div className='footer-form'>
        <h3 className='title-footer'>{
          this.props.place === 'Main' ?
            strings.form_title[1] : strings.form_title[0]}</h3>
        <form className='payform' onSubmit={submitForm}>
          <input type="hidden" name="lang" value={this.props.langValue} />
          <div className='input'>
            {formErrorName && (
              <span className={'valid-message'}>
                {strings.form_validation.name}
              </span>
            )}
            {formErrorNameLength && (
              <span className={'valid-message'}>
                {strings.form_validation.name_length}
              </span>
            )}
            {formErrorNameValue && (
              <span className={'valid-message'}>
                {strings.form_validation.name_value}
              </span>
            )}
            <input
              className={
                formErrorName || formErrorNameLength || formErrorNameValue
                  ? 'text-input error'
                  : 'text-input'
              }
              type='text'
              id='input-name'
              name='name'
              maxLength={129}
              value={formNameValue}
              placeholder={strings.input_name}
              onChange={nameForm}
              disabled={formSubmitLoading}
            />
            <label htmlFor='input-name' className={'label-hidden'}>
              Name
            </label>
          </div>
          <div className='input'>
            {formErrorContact && (
              <span className={'valid-message'}>
                {strings.form_validation.contacts}
              </span>
            )}
            {formErrorContactLength && (
              <span className={'valid-message'}>
                {strings.form_validation.contacts_length}
              </span>
            )}
            <input
              className={
                formErrorContact || formErrorContactLength
                  ? 'text-input error'
                  : 'text-input'
              }
              type='text'
              id='input-contact'
              name='contact'
              maxLength={129}
              value={formContactValue}
              placeholder={strings.input_contact}
              onChange={contactFrom}
              disabled={formSubmitLoading}
            />
            <label htmlFor='input-contact' className={'label-hidden'}>
              Contact
            </label>
          </div>
          <div className='input'>
            {formErrorCommentLength && (
              <span className={'valid-message'}>
                {strings.form_validation.comment_length}
              </span>
            )}
            <input
              className='text-input'
              type='text'
              id='input-comment'
              name='comment'
              value={formCommentValue}
              placeholder={strings.input_comment}
              maxLength={1001}
              onChange={commentForm}
              disabled={formSubmitLoading}
            />
            <label htmlFor='input-comment' className={'label-hidden'}>
              Comment
            </label>
          </div>
          {requestMessageStatus.length === 0 ? (
            <button
              type='submit'
              className={formSubmitLoading ? 'submit loading' : 'submit'}
              disabled={formSubmitLoading}
            >
              <div className='submit-text'>
                {formSubmitLoading
                  ? strings.button_loading
                  : strings.button_name}
                {formSubmitLoading && (
                  <div className='loading-dots'>{formLoadingDots}</div>
                )}
              </div>
            </button>
          ) : (
            <div className='request-messages'>
              {requestMessageStatus === '200' && (
                <span className='valid'>
                  {strings.form_validation.response_valid}
                </span>
              )}
              {requestMessageStatus === '201' && (
                <span className='warning'>
                  {strings.form_validation.response_warning}
                </span>
              )}
              {requestMessageStatus === '404' && (
                <span className='error'>
                  {strings.form_validation.response_error}
                </span>
              )}
            </div>
          )}
        </form>
      </div>
    )
  }
}

export default FooterForm
