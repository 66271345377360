import React, {Component} from 'react';
import './page404.css';
import Dino from '../../../../img/ddk.gif';

class Page404 extends Component {

    render() {
        const { location } = this.props;
        return (
            <div className={"wrap404"}>
                <div className={"picture"}>
                    <img src={ Dino } alt="Page404" width="449" height="337"/>
                </div>
                <h2>No match found for <code>{location.pathname}</code></h2>
            </div>
        )
    }
}

export default Page404;