import React from 'react'
import OfferEN from './lang/en';
import OfferRU from './lang/ru';
// style
import './style.css'

const OfferPage = (props) => {

  const textController = () => {

    switch (props.lang) {
      case 'de':
        return <OfferEN />  
      case 'ru':
        return <OfferRU />  
      case 'en':
        return <OfferEN />  
  
      default:
        return <OfferEN />
    }
  }
  return (
    <div className='offer-page'>{textController()}</div>
  )
}

export default OfferPage