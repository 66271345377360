import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

import './fonts.css';
import './section-margin.css';
//Components
import Home from '../Main/Home/Home';
import Page404 from '../Main/Home/Page404/Page404';
import Works from '../Works/Works';
import Project from '../Project/Project';
import ScrollToTop from './ScrollToTop';
import Carenty from '../Project/carenty/Carenty';
import OfferPage from '../Offer';


function App() {
    // const history = useHistory()

    const [lang, setLeng] = useState(localStorage.getItem('lang') || 'en')

    const changeLang = (val) => {
        updateLocalStorage(val)
        setLeng(val)
    }

    const updateLocalStorage = (val) => {
        localStorage.setItem('lang', val)
    }

    /*  const changeUrl = val => {
         history.push(val)
     } */

    return (
        <>

            < Router /* history={history} */>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" render={() => <Home changeLang={changeLang} lang={lang} />} />
                    <Route exact path='/works' render={() => <Works changeLang={changeLang} lang={lang} />} />
                    <Route exact path='/project' render={() => <Project changeLang={changeLang} lang={lang} />} />
                    <Route exact path='/carenty' render={() => <Carenty changeLang={changeLang} lang={lang} />} />
                    <Route exact path='/offer' render={() => <OfferPage changeLang={changeLang} lang={lang} />} />
                    <Route component={Page404} />
                </Switch>
            </Router >

        </>
    );


}

export default App;
