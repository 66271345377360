import React, { Component } from 'react';
import './design-animation.css';

function DesignAnimation() {

    return (
        <div className="design-animation">
            <div className="head_r">
                <div className="wrap_pos">
                    <div className="hat_r"></div>
                    <div className="eye_wrap"></div>
                    <div className="eye_r eye_1"></div>
                    <div className="eye_r eye_2"></div>
                    <div className="eye_r eye_3"></div>
                    <div className="eye_r eye_4"></div>
                    <div className="nose_r"></div>
                    <div className="mouth_r"></div>
                </div>
            </div>
            <div className="stomach_r"></div>
            <div className="picture_r"></div>
            <div className="legs-start">
                <div className="leg_r leg_1">
                    <div className="wrap_rel">
                        <div className="hip_r"></div>
                        <div className="knee_r knee_1"></div>
                        <div className="knee_r knee_2"></div>
                        <div className="knee_r knee_3"></div>
                    </div>
                </div>
                <div className="leg_r leg_2">
                    <div className="wrap_rel">
                        <div className="hip_r"></div>
                        <div className="knee_r knee_1"></div>
                        <div className="knee_r knee_2"></div>
                        <div className="knee_r knee_3"></div>
                    </div>
                </div>
                <div className="leg_r leg_3">
                    <div className="wrap_rel">
                        <div className="hip_r"></div>
                        <div className="knee_r knee_1"></div>
                        <div className="knee_r knee_2"></div>
                        <div className="knee_r knee_3"></div>
                    </div>
                </div>
            </div>
            <div className="hand_left-start">
                <div className="hand_left">
                    <div className="wrap_rel">
                        <div className="hand_left-str hand_left1"></div>
                        <div className="hand_left-str hand_left2"></div>
                        <div className="hand_left-str hand_left3"></div>
                    </div>
                </div>
            </div>

            <div className="hand_right">
                <div className="wrap_rel">
                    <div className="hand_right-1"></div>
                    <div className="hand_right-2"></div>
                    <div className="hand_right-3"></div>
                    <div className="hand_right-4"></div>
                    <div className="hand_right-5"></div>
                </div>
            </div>
            <div className="hand_up">
                <div className="wrap_rel">
                    <div className="hand_up1"></div>
                    <div className="hand_up2"></div>
                    <div className="hand_up3"></div>
                </div>
            </div>

        </div>
    )

}

export default DesignAnimation;