import React from 'react';
import { useEffect, useState, useRef, useMemo } from 'react';
import LocalizedStrings from 'react-localization';
import Nav from '../Main/Home/Header/Nav';
import FooterComp from '../FooterComp/FooterComp';
import BottonMore from '../BottonMore/BottonMore'
import WorkCart from '../WorkCart/WorkCart';

// style
import './works.css'

import img from '../../img/workPage/carenty.jpg'
import img2 from '../../img/workPage/dooozen.png'
import img3 from '../../img/workPage/Progalit.png'
import img4 from '../../img/workPage/loader.png'
import img5 from '../../img/workPage/robyn.png'
import pdg1 from '../../img/pdf/Carenty.pdf'
import pdg2 from '../../img/pdf/Co-Loader.pdf'
import pdg3 from '../../img/pdf/Dooozen.pdf'
import pdg4 from '../../img/pdf/Progalit.pdf'
import pdg5 from '../../img/pdf/Robyn Capital.pdf'

const strings = new LocalizedStrings({
  en: {
    titleWorks: ['Our Work'],
    title: ['Short and concise title'],
    titleBottom: ['Load more'],
    workes: [
      {
        "image": "/static/media/carenty.58c8a2e5.jpg",
        "title": "Carenty",
        "subtitle": ["Marketplace platform for car sharing"],
        "text": "Carenty - is a SaaS platform for car rental, built on the Car Sharing model. ",
        "technology": [
          "React.js",
          "MySQL Data base",
          "Zend Framework",
          "Websockets"
        ],
        "readMore": 'Read more',
        "pdf": '/static/media/Carenty.8492c4e0.pdf'
      },
      {

        "image": "/static/media/dooozen.3f655efa.png",
        "title": "Dooozen",
        "subtitle": [
          "Dozen SaaS solution for sales managers:",
          "web application and chrome extension"
        ],
        "text": "Dooozen.io - Web Application and Chrome Extension that automates lead generation on LinkedIn.",
        "technology": [
          "React.js",
          "Chrome Extensions",
          "Hooks",
          "ESLint",
          "TypeScript",
          "REST-api",
          "JS",
          "HTML5",
          "CSS3",
          "Sass",
          "Material-UI"
        ],
        "readMore": 'Read more',
        "pdf": '/static/media/Dooozen.c3669d03.pdf'
      },
      {

        "image": "/static/media/loader.c17f3efc.png",
        "title": "Co-Loader",
        "subtitle": [
          "SaaS solution for sellers and buyers on Amazon:",
          "Delivery management system "
        ],
        "text": "Co-loader is a delivery management system that simplifies inbound logistics for e-commerce sellers.",
        "technology": [
          "PHP 7",
          "Symfony 5",
          "JS",
          "HTML5",
          "CSS3",
          "React.js",
          "Redux",
          "TypeScript",
          "Material-UI"
        ],
        "readMore": 'Read more',
        "pdf": '/static/media/Co-Loader.ef49cd11.pdf'
      },
      {

        "image": "/static/media/robyn.fab478bc.png",
        "title": "Robyn Capital",
        "subtitle": ["Website for fintech company"],
        "text": "Robyn Capital - is a fintech organization founded to provide personal finance solutions for employees and founders of venture capital firms.",
        "technology": [
          "WordPress",
          "PHP 7",
          "MySQL",
          "jQuery",
          "Bootstrap",
          "Salesforce"
        ],
        "readMore": 'Read more',
        "pdf": '/static/media/Robyn Capital.3dd6bff8.pdf'
      },
      {

        "image": "/static/media/Progalit.369b8237.png",
        "title": "Progalit",
        "subtitle": ["Telegram bot for healthcare"],
        "text": "Progalit is a healthcare chatbot, created to optimize the online treatment of patients suffering from kidneys stones.",
        "technology": [
          "Symfony 5",
          "Symfony Messenger",
          "Telegram API",
          "TelegramBotBundle"
        ],
        "readMore": 'Read more',
        "pdf": '/static/media/Progalit.b6955f28.pdf'
      },
    ]
  },
  ru: {
    titleWorks: ['Наши работы'],
    title: ['Краткий и очень емкий заголовк'],
    titleBottom: ['Загрузить еще'],
    workes: [
      {

        "image": "/static/media/carenty.58c8a2e5.jpg",
        "title": "Carenty",
        "subtitle": ["Маркетплейс платформа для каршеринга"],
        "text": "Carenty — SaaS-платформа для аренды автомобилей, построенная по модели Car Sharing.",
        "technology": [
          "React.js",
          "MySQL Data base",
          "Zend Framework",
          "Websockets",
          "Telegram API",
          "TelegramBotBundle"
        ],
        "readMore": 'Читать далее',
        "pdf": '/static/media/Carenty.8492c4e0.pdf'
      },
      {

        "image": "/static/media/dooozen.3f655efa.png",
        "title": "Dooozen",
        "subtitle": [
          "Dozen SaaS-решение для менеджеров по продажам:",
          "веб-приложение и расширение для Chrome"
        ],
        "text": "Dooozen.io — веб-приложение и расширение для Chrome, которое автоматизирует генерацию лидов в LinkedIn.",
        "technology": [
          "React.js",
          "Chrome Extensions",
          "Hooks",
          "ESLint",
          "TypeScript",
          "REST-api",
          "JS",
          "HTML5",
          "CSS3",
          "Sass",
          "Material-UI"
        ],
        "readMore": 'Читать далее',
        "pdf": '/static/media/Dooozen.c3669d03.pdf'
      },
      {

        "image": "/static/media/loader.c17f3efc.png",
        "title": "Co-Loader",
        "subtitle": [
          "SaaS-решение для продавцов и покупателей на Amazon:",
          "Система управления поставками "
        ],
        "text": "Co-loader — система управления поставками, которая упрощает входящую логистику для e-commerce продавцов.",
        "technology": [
          "PHP 7",
          "Symfony 5",
          "JS",
          "HTML5",
          "CSS3",
          "React.js",
          "Redux",
          "TypeScript",
          "Material-UI"
        ],
        "readMore": 'Читать далее',
        "pdf": '/static/media/Co-Loader.ef49cd11.pdf'
      },
      {

        "image": "/static/media/robyn.fab478bc.png",
        "title": "Robyn Capital",
        "subtitle": ["Сайт финтех компании"],
        "text": "Robyn Capital — финтех-организация, основанная для предоставления решений в области личных финансов для сотрудников и основателей фирм венчурного капитала.",
        "technology": [
          "WordPress",
          "PHP 7",
          "MySQL",
          "jQuery",
          "Bootstrap",
          "Salesforce"
        ],
        "readMore": 'Читать далее',
        "pdf": '/static/media/Robyn Capital.3dd6bff8.pdf'
      },
      {

        "image": "/static/media/Progalit.369b8237.png",
        "title": "Progalit",
        "subtitle": ["Медицинский Telegram-бот"],
        "text": "Progalit — медицинский чат-бот, созданный для оптимизации онлайн-лечения пациентов с камнями в почках. ",
        "technology": [
          "Symfony 5",
          "Symfony Messenger",
          "Telegram API",
          "TelegramBotBundle"
        ],
        "readMore": 'Читать далее',
        "pdf": '/static/media/Progalit.b6955f28.pdf'
      },
    ]
  },
  de: {
    titleWorks: ['Unsere Werke'],
    title: ['Kurzer und prägnanter Titel'],
    titleBottom: ['Mehr laden'],
    workes: [
      {

        "image": "/static/media/carenty.58c8a2e5.jpg",
        "title": "Carenty",
        "subtitle": ["Ist eine Plattform für Carsharing"],
        "text": "Carenty – ist eine SaaS-Plattform für die Autovermietung, die auf dem Car-Sharing-Modell basiert.",
        "technology": [
          "React.js",
          "MySQL-Datebank",
          "Zend Framework",
          "Websockets",
          "Telegram API",
          "TelegramBotBundle"
        ],
        "readMore": 'Weiterlesen',
        "pdf": '/static/media/Carenty.8492c4e0.pdf'
      },
      {

        "image": "/static/media/dooozen.3f655efa.png",
        "title": "Dooozen",
        "subtitle": [
          "Dooozen SaaS ist eine Lösung für Vertriebsleiter:",
          "Webanwendung und Chrome-Erweiterung"
        ],
        "text": "Dooozen.io – Webanwendung und Chorme-Erweiterung, die die Lead-Generierung auf LinkedIn automatisiert.",
        "technology": [
          "React.js",
          "Chrome-Erweiterung",
          "Hooks",
          "ESLint",
          "TypeScript",
          "REST-api",
          "JS",
          "HTML5",
          "CSS3",
          "Sass",
          "Material-UI"
        ],
        "readMore": 'Weiterlesen',
        "pdf": '/static/media/Dooozen.c3669d03.pdf'
      },
      {

        "image": "/static/media/loader.c17f3efc.png",
        "title": "Co-Loader",
        "subtitle": [
          "Ist eine SaaS-Lösung für Verkäufer und Käufer auf Amazon:",
          "Liefermanagementsystem"
        ],
        "text": "Co-Loader ist ein Liefermanagementsystem, das die Logistik für E-Commerce-Verkäufer vereinfacht.",
        "technology": [
          "PHP 7",
          "Symfony 5",
          "JS",
          "HTML5",
          "CSS3",
          "React.js",
          "Redux",
          "TypeScript",
          "Material-UI"
        ],
        "readMore": 'Weiterlesen',
        "pdf": '/static/media/Co-Loader.ef49cd11.pdf'
      },
      {

        "image": "/static/media/robyn.fab478bc.png",
        "title": "Robyn Capital",
        "subtitle": ["Website für Fintech-Unternehmen"],
        "text": "Robyn Capital – ist eine Fintech-Organisation, die gegründet wurde, um persönliche Finanzlösungen für Mitarbeiter und Gründer von Risikokapitalunternehmen bereitzustellen.",
        "technology": [
          "WordPress",
          "PHP 7",
          "MySQL",
          "jQuery",
          "Bootstrap",
          "Salesforce"
        ],
        "readMore": 'Weiterlesen',
        "pdf": '/static/media/Robyn Capital.3dd6bff8.pdf'
      },
      {

        "image": "/static/media/Progalit.369b8237.png",
        "title": "Progalit",
        "subtitle": ["Telegramm-Bot für das Gesundheitswesen"],
        "text": "Progalit ist ein Gesundheits-Chatbot, der entwickelt wurde, um die Online-Behandlung von Patienten mit Nierensteinen zu optimieren.",
        "technology": [
          "Symfony 5",
          "Symfony Messenger",
          "Telegram API",
          "TelegramBotBundle"
        ],
        "readMore": 'Weiterlesen',
        "pdf": '/static/media/Progalit.b6955f28.pdf'
      },
    ]
  },
});

let workes = [
  {
    "id": 0,
    "image": "/static/media/carenty.1089015a.png",
    "title": "Carenty",
    "subtitle": ["Marketplace platform for car sharing"],
    "text": "Carenty - is a SaaS platform for car rental, built on the Car Sharing model. ",
    "technology": [
      "React.js",
      "MySQL Data base",
      "Zend Framework",
      "Websockets"
    ],
    "pdf": '/static/media/Carenty.8492c4e0.pdf'
  },
  {
    "id": 1,
    "image": "/static/media/dooozen.3f655efa.png",
    "title": "Dooozen",
    "subtitle": [
      "Dozen SaaS solution for sales managers:",
      "web application and chrome extension"
    ],
    "text": "Dooozen.io - Web Application and Chrome Extension that automates lead generation on LinkedIn.",
    "technology": [
      "React.js",
      "Chrome Extensions",
      "Hooks",
      "ESLint",
      "TypeScript",
      "REST-api",
      "JS",
      "HTML5",
      "CSS3",
      "Sass",
      "Material-UI"
    ],
    "pdf": '/static/media/Dooozen.c3669d03.pdf'
  },
  {
    "id": 2,
    "image": "/static/media/loader.c17f3efc.png",
    "title": "Co-Loader",
    "subtitle": [
      "SaaS solution for sellers and buyers on Amazon:",
      "Delivery management system "
    ],
    "text": "Co-loader is a delivery management system that simplifies inbound logistics for e-commerce sellers.",
    "technology": [
      "PHP 7",
      "Symfony 5",
      "JS",
      "HTML5",
      "CSS3",
      "React.js",
      "Redux",
      "TypeScript",
      "Material-UI"
    ],
    "pdf": '/static/media/Co-Loader.ef49cd11.pdf'
  },
  {
    "id": 3,
    "image": "/static/media/robyn.fab478bc.png",
    "title": "Robyn Capital",
    "subtitle": ["Website for fintech company"],
    "text": "Robyn Capital - is a fintech organization founded to provide personal finance solutions for employees and founders of venture capital firms.",
    "technology": [
      "WordPress",
      "PHP 7",
      "MySQL",
      "jQuery",
      "Bootstrap",
      "Salesforce"
    ],
    "pdf": '/static/media/Robyn Capital.3dd6bff8.pdf'
  },
  {
    "id": 4,
    "image": "/static/media/Progalit.369b8237.png",
    "title": "Progalit",
    "subtitle": ["Telegram bot for healthcare"],
    "text": "Progalit is a healthcare chatbot, created to optimize the online treatment of patients suffering from kidneys stones.",
    "technology": [
      "Symfony 5",
      "Symfony Messenger",
    ],
    "pdf": '/static/media/Progalit.b6955f28.pdf'
  },
]

function Works(props) {
  //ФУНКЦИЯ ЛОКАЛИЗАТОР
  strings.setLanguage(props.lang)

  let work = useRef(null)
  const [card, setCard] = useState([])
  const [works, setWorks] = useState([])
  const [isVisible, setVisible] = useState(false)
  let a = 4
  let b = 8
  const [count, setCount] = useState([a, b])


  /*  useEffect(() => {
     setCard(strings.workes)
   }, [])
 
   const arrCount = ([a, b]) => {
     addArr(a, b)
     setCount([a + 4, b + 4])
     console.log(a, b)
   }
 
   const addArr = (a, b) => {
     let arr = card.slice(a, b)
     setWorks(prevState => [...prevState, ...arr])
     if (works[works.length - 1] == card[card.length - 1]) {
       work.current.style.display = 'none'
     }
 
   }
 
   const handleScroll = () => {
     if (isVisible) {
       arrCount(count)
     }
   }
 
   const callbackFunction = entries => {
     const [entry] = entries
     setVisible(entry.isIntersecting)
   }
 
   const options = useMemo(() => {
     return {
       root: null,
       rootMargin: '0px',
       threshold: 0.1
     }
   }, [])
 
   useEffect(() => {
     const observer = new IntersectionObserver(callbackFunction, options)
     const currentTarget = work.current
     if (currentTarget) observer.observe(currentTarget);
     return () => {
       if (currentTarget) observer.unobserve(currentTarget);
     }
   }, [work, options])
 
   useEffect(() => {
     handleScroll()
   }, [isVisible])
 
 
   let arr3 = card.slice(0, 4) */

  return (
    <div className="works ">
      <Nav changeLang={props.changeLang} lang={props.lang} />
      <div className="works-wrap">
        <div className='container '>
          <div className='works-desc '>
            <div className="works-desc__sub-title">
              {strings.titleWorks.map(item => {
                return (
                  <h2 >
                    {item}
                  </h2>)
              })}
            </div>
          </div>

          <div className="works-carts">
            {
              strings.workes.map((item, index) => {
                return (
                  <WorkCart key={index} img={item.image} title={item.title} subtitle={item.subtitle} text={item.text} technology={item.technology} pdf={item.pdf} readMore={item.readMore} />
                )
              })
            }
            {/*     {
              arr3.map((item, id) => {
                return (
                  <WorkCart key={id} img={item.image} title={item.title} subtitle={item.subtitle} text={item.text} technology={item.technology} pdf={item.pdf} />
                )
              })
            }
            {
              works.map((item, id) => {
                return (
                  <WorkCart key={id} img={item.image} title={item.title} subtitle={item.subtitle} text={item.text} technology={item.technology} pdf={item.pdf} />
                )
              })
            } */}
          </div>
          {/*  <div className='works-botton' ref={work}  >
            {strings.titleBottom.map(item => {
              return (
                <BottonMore>
                  {item}
                </BottonMore>
              )
            })}
          </div> */}
        </div>
        <FooterComp lang={props.lang} />
      </div>
    </div >
  )

}

export default Works;